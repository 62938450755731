<template>
  <div>
    <div
      v-if="isLoadingProducts"
      class="d-flex align-items-center justify-content-center mt-20"
    >
      <p>
        <span
          class="spinner-border spinner-border-sm align-middle me-2"
        />Loading products
      </p>
    </div>
    <div v-else class="mb-40">
      <!-- Begin::Search -->
      <div class="bg-white d-flex row-fluid mb-12">
        <input
          type="search"
          class="form-control py-6"
          placeholder="Search product"
          @input="onSearch"
        />
      </div>
      <!-- End::Search -->
      <!-- Begin::Products -->
      <div class="products-grid row g-5 d-flex flex-grow-1">
        <div v-for="(product, idx) in products" :key="idx" class="col-lg-4">
          <div
            class="product card card-stretch shadow mb-5"
            v-if="product.showOnMarketplace"
          >
            <div class="card-body pb-2">
              <div class="product-image mb-6">
                <img
                  v-if="product.picture && product.picture.blobSrc"
                  class="product-thumbnail d-block mx-auto"
                  :src="product.picture.blobSrc"
                />
              </div>
              <div
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-between
                "
              >
                <!-- Begin::Product Details -->
                <div>
                  <div class="d-flex flex-row align-items-center">
                    <h3 class="card-title me-3">{{ product.name }}</h3>
                    <p
                      class="badge badge-light-info mb-2"
                      :class="getProductCategoryBadgeClass(product.category)"
                    >
                      {{ product.category }}
                    </p>
                  </div>
                  <p class="text-gray-600 mb-0">{{ product.reference }}</p>
                </div>
                <!-- End::Product Details -->
                <!-- Begin::Product Price -->
                <div>
                  <p class="product-price-label text-gray-600 mb-0 text-end">
                    Unit price
                  </p>
                  <p class="product-price text-gray-800 mb-0 text-end">
                    {{ product.price }}€
                  </p>
                </div>
                <!-- End::Product Price -->
              </div>
            </div>
            <div class="product-footer card-footer my-0 py-6">
              <div
                class="
                  d-flex
                  flex-row
                  justify-content-start
                  align-items-md-center
                "
              >
                <a
                  class="btn btn-icon btn-light"
                  @click="removeProductFromOrder(product)"
                  ><i class="bi bi-dash"></i
                ></a>
                <p class="mb-0 mx-6">{{ getProductOrderQuantity(product) }}</p>
                <a
                  class="btn btn-icon btn-light"
                  @click="addProductToOrder(product)"
                  ><i class="bi bi-plus"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End::Products -->
      <!-- Begin::Order summary -->
      <transition name="fade">
        <div class="order-bar g-5 py-4 px-6" v-if="getOrderTotalQty > 0">
          <div
            class="
              d-flex
              flex-row
              justify-content-md-between
              align-items-md-center
            "
          >
            <div class="d-flex flex-column justify-content-start">
              <p class="mb-0 me-6 text-gray-600">Items</p>
              <p class="mb-0 me-6 text-gray-800">{{ getOrderTotalQty }}</p>
            </div>
            <div class="d-flex flex-row align-items-center">
              <div class="d-flex flex-column justify-content-end">
                <p class="mb-0 me-6 text-gray-600 text-end">Total</p>
                <p class="mb-0 me-6 text-end">{{ getOrderPrice }}€</p>
              </div>
              <button
                class="btn btn-primary"
                @click="openCheckoutModal"
              >
                Checkout
              </button>
            </div>
          </div>
        </div>
      </transition>
      <!-- End::Order summary -->
      <NewOrder
        name="new-order-modal"
        v-model="isNewOrderModalOpen"
        @confirm="onOrderConfirmed"
      >
        <template v-slot:title>Order</template>
      </NewOrder>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import NewOrder from "@/components/modals/marketplace/NewOrder.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

const clinicId = JwtService.getUser().clinic.id;

export default {
  components: {
    NewOrder,
  },
  data() {
    return {
      isLoadingProducts: true,
      isNewOrderModalOpen: false,
      products: [],
      allProducts: [],
      order: [],
      clinic: {},
    };
  },
  created() {
    this.fetchProducts();
    this.fetchClinic();
    setCurrentPageBreadcrumbs("Marketplace", []);
  },
  methods: {
    onSearch(e) {
      const query = e.target.value.toUpperCase();
      this.products = this.allProducts.filter((product) => {
        return (
          product.name.toUpperCase().includes(query) ||
          product.category.toUpperCase().includes(query) ||
          product.reference.toUpperCase().includes(query)
        );
      });
    },
    fetchProducts() {
      this.isLoadingProducts = true;
      ApiService.query("/products")
        .then(({ data }) => {
          this.allProducts = data.results;
          this.products = data.results;
          this.isLoadingProducts = false;

          // Fetch products' images
          const imagesBlobsPromises = this.allProducts.map((product) => {
            if (!product.picture || !product.picture.filename) return null;
            return ApiService.fetchImageWithAuth(`/products/files/${product.picture.filename}`);
          });

          Promise.all(imagesBlobsPromises).then(imagesBlobs => {
            console.log(imagesBlobs);
            for (let i = 0; i < this.allProducts.length; i++) {
              if (!imagesBlobs[i]) continue;
              this.allProducts[i].picture['blobSrc'] = imagesBlobs[i];
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    fetchClinic() {
      ApiService.get("clinics", clinicId)
        .then((res) => {
          this.clinic = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /**
     * returns badge classes for the categories
     */
    getProductCategoryBadgeClass(category) {
      return {
        "badge-light-info": category === "Pigment",
        "badge-light-danger": category === "Instrument",
      };
    },
    /**
     * removes 1 product from the order
     */
    removeProductFromOrder(product) {
      const idx = this.order.findIndex(el => el.product.id == product.id);
      if (idx !== -1) {
        if (this.order[idx].qty > 1) {
          this.order[idx].qty--;
        } else {
          this.order.splice(idx, 1);
        }
      }
    },
    /**
     * adds a product to the order
     */
    addProductToOrder(product) {
      const idx = this.order.findIndex(el => el.product.id == product.id);
      if (idx >= 0) {
        this.order[idx].qty++;
      } else {
        this.order.push({ product: product, qty: 1 });
      }
    },
    /**
     * returns the qty of a product in the order
     */
    getProductOrderQuantity(product) {
      const idx = this.order.findIndex(el => el.product.id == product.id);
      return idx >= 0 ? this.order[idx].qty : 0;
    },
    onOrderConfirmed() {
      return;
    },
    openCheckoutModal() {
      this.$vfm.show("new-order-modal", {
        order: this.order,
        clinic: this.clinic,
      });
    },
  },
  computed: {
    getOrderTotalQty() {
      let total = 0;
      this.order.forEach((el) => {
        total += el.qty;
      });

      return total;
    },
    getOrderPrice() {
      let totalPrice = 0;
      this.order.forEach((el) => {
        totalPrice += el.product.price * el.qty;
      });
      return totalPrice.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  box-shadow: none !important;
  border: 1px solid #efefef;
}
.product-image {
  height: 200px;
  background: rgba(128, 128, 128, 0.2);
}
.product-price {
  font-size: 1.2rem;
}
.product-footer {
  border-top: none;
}
.products-grid {
}
.order-bar {
  position: fixed;
  width: 600px;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 56px;
  border: 1px solid #efefef;
  background: #fff;
  -webkit-box-shadow: 0px 20px 32px 0px rgba(213, 214, 224, 0.25);
  box-shadow: 0px 20px 32px 0px rgba(213, 214, 224, 0.25);
}
.product-thumbnail {
  max-width: 100%;
  max-height: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>