<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    @beforeOpen="(event) => processParams(event.ref.params._rawValue)"
  >
    <!-- Begin::Header -->
    <div class="modal__header">
      <span class="modal__title">
        <slot name="title"></slot>
      </span>
    </div>
    <!-- End::Header -->
    <!-- Begin::Body -->
    <div class="modal__content">
      <!-- Begin::Details section -->
      <div class="row" v-if="bankDetailsReference">
        <h5 class="mb-3 mt-6">Bank transfer information</h5>
        <div v-if="order.paymentMethod == 'BANK_TRANSFER'" class="col-12">
          <p>Please send your bank transfer to:</p>
          <p>
            NEORIS<br />
            RIB: 30087 33042 00020447001 33<br />
            IBAN: FR76 3008 7330 4200 0204 4700 133<br />
            BIC: CMCIFRPP<br />
            Reference: "{{ bankDetailsReference }}"
          </p>
          <p>
            <i>Your order will be approved and shipped only once your payment has been received.</i>
          </p>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-lg-6 col-12">
          <div class="mb-10">
            <h5 class="fw-bolder mb-3">Billing address</h5>
            <p v-if="data.clinic">{{ getBillingAddressStr }}</p>
            <div
              class="
                form-check form-check-custom form-check-sm form-check-solid
              "
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="billing-is-shipping"
                v-model="billingAddrIsShippingAddr"
              />
              <label
                class="form-check-label mb-0 text-gray-600"
                for="billing-is-shipping"
                >Use as shipping address</label
              >
            </div>
          </div>
          <form
            @submit.prevent="createOrder"
            ref="form"
            v-if="!billingAddrIsShippingAddr"
          >
            <h5 class="mb-3">Shipping address</h5>
            <div class="d-flex flex-row row mb-12">
              <!-- Begin::Col -->
              <div class="d-flex flex-column col-12 mb-4">
                <label for="shipping-street">Address line 1*</label>
                <input
                  type="text"
                  class="form-control"
                  id="shipping-street"
                  v-model="order.shippingAddress.street"
                  placeholder="Street"
                  required
                />
              </div>
              <!-- Begin::Col -->
              <div class="d-flex flex-column col-12 mb-4">
                <label for="shipping-street">Address line 2</label>
                <input
                  type="text"
                  class="form-control"
                  id="shipping-street"
                  v-model="order.shippingAddress.street2"
                  placeholder="Street"
                />
              </div>
              <!-- End::Col -->
              <!-- Begin::Col -->
              <div class="d-flex flex-column col-6 mb-4">
                <label for="shipping-zip">ZIP*</label>
                <input
                  type="text"
                  class="form-control"
                  id="shipping-zip"
                  v-model="order.shippingAddress.zip"
                  placeholder="Zip"
                  required
                />
              </div>
              <!-- End::Col -->
              <!-- Begin::Col -->
              <div class="d-flex flex-column col-6 mb-4">
                <label for="shipping-city">City*</label>
                <input
                  type="text"
                  class="form-control"
                  id="shipping-city"
                  v-model="order.shippingAddress.city"
                  placeholder="City"
                  required
                />
              </div>
              <!-- End::Col -->
              <!-- Begin::Col -->
              <div class="d-flex flex-column col-12 mb-4">
                <label for="shipping-state">State*</label>
                <input
                  type="text"
                  class="form-control"
                  id="shipping-state"
                  v-model="order.shippingAddress.state"
                  placeholder="State"
                  required
                />
              </div>
              <!-- End::Col -->
              <!-- Begin::Col -->
              <div class="d-flex flex-column col-12 mb-4">
                <label for="shipping-country">Country*</label>
                <input
                  type="text"
                  class="form-control"
                  id="shipping-country"
                  v-model="order.shippingAddress.country"
                  placeholder="Country"
                  required
                />
              </div>
              <!-- End::Col -->
            </div>
            <!-- End::Details section -->
          </form>
        </div>
        <div class="col-lg-6 col-12" v-if="data.order">
          <h5 class="mb-3">Order summary</h5>
          <div class="row">
            <p class="col-5 text-muted">Product</p>
            <p class="col-2 text-muted">Qty</p>
            <p class="col-2 text-muted">Per unit</p>
            <p class="col-3 text-muted text-end">Total</p>
          </div>
          <div v-for="(item, idx) in data.order" :key="idx">
            <div class="separator"></div>
            <div class="row my-3">
              <p class="col-5 mb-0">{{ item.product.name }}</p>
              <p class="col-2 mb-0">{{ item.qty }}</p>
              <p class="col-2 mb-0">{{ item.product.price }} €</p>
              <p class="col-3 mb-0 text-end">
                {{ (item.product.price * item.qty).toFixed(2) }} €
              </p>
            </div>
          </div>
          <div class="separator mb-10"></div>
          <div class="row mb-3">
            <p class="text-gray-600 mb-2 me-4 col-4">Total price excl. VAT:</p>
            <h6 class="col-5">{{ getTotalPrice.toFixed(2) }} €</h6>
          </div>
          <div class="row mb-3">
            <p class="text-gray-600 mb-2 me-4 col-4">VAT ({{ getVatPercentage }}%):</p>
            <h6 class="col-5">{{ getTotalVat.toFixed(2) }} €</h6>
          </div>
          <div class="row">
            <p class="text-gray-600 mb-2 me-4 col-4">Total price incl. VAT:</p>
            <h6 class="col-5">{{ getTotalPriceWithVat.toFixed(2) }} €</h6>
          </div>

          <h5 class="mb-3 mt-6">
            Payment method
            <small>
              <i>(can be configured in your settings)</i>
            </small>
          </h5>
          <div class="row">
            <div class="col-12">
              <select
                class="form-select form-select-sm me-2"
                v-model="order.paymentMethod"
              >
                <option
                  v-for="paymentMethod in paymentMethods"
                  :key="paymentMethod.id"
                  :value="paymentMethod.id"
                >
                  {{ paymentMethodToStr(paymentMethod) }}
                </option>
                <option value="BANK_TRANSFER">
                  Bank Transfer (instructions given once the order is placed)
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End::Header -->
    <!-- Begin::Footer -->
    <div class="modal__action" style="justify-content: space-between;">
      <template v-if="isTotalAmountOverLimit">
        <div class="alert alert-danger d-block" role="alert">
          Marketplace orders are currently limited to {{ maxPaymentAmount.toFixed(2) }} € (including taxes).
          Please select "Bank Transfer" payment method or split your order to proceed to online checkout.
        </div>
      </template>
      <template v-else>
        <div>
          <label v-if="!bankDetailsReference">
            <input type="checkbox" v-model="termsAndConditionsAccepted" required />
            I accept the <router-link to="/terms-and-conditions" target="_blank">General Terms and Conditions (CGV)</router-link>
          </label>
        </div>
        <div>
          <template v-if="!bankDetailsReference">
            <button
              class="btn btn-lg btn-primary me-2"
              :disabled="isTotalAmountOverLimit || orderLoading"
              @click.prevent="onClickConfirm"
            >
              Confirm and pay
              <span v-if="order.paymentMethod != 'BANK_TRANSFER'"> (automatic payment)</span>
            </button>
            <button class="btn btn-lg btn-secondary" @click="close">Cancel</button>
          </template>
          <template v-else>
            <button class="btn btn-lg btn-secondary" @click="redirectToOrders">Ok</button>
          </template>
        </div>
      </template>
    </div>
    <!-- End::Header -->
    <button class="modal__close btn btn-icon btn-link" @click="close">
      <i class="bi bi-x-lg"></i>
    </button>
  </vue-final-modal>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useToast } from "vue-toastification";
import _ from "lodash";

const clinicId = JwtService.getUser().clinic.id;

export default {
  emits: ["confirm"],
  setup() {
    return {
        toast: useToast(),
    };
  },
  data() {
    return {
      bankDetailsReference: null,
      paymentMethods: [],
      orderLoading: false,
      data: {},
      order: {
        shippingAddress: {},
        billingAddress: {},
        products: [],
        paymentMethod: null,
      },
      termsAndConditionsAccepted: false,
      billingAddrIsShippingAddr: true,
      maxPaymentAmount: 5000,
    };
  },
  computed: {
    getBillingAddress() {
      if (this.data && this.data.clinic) {
        return this.data.clinic.addresses.find((addr) => addr.label === "BILLING")
          || this.data.clinic.addresses.find((addr) => addr.label === "DEFAULT");
      } else {
        return null;
      }
    },
    getBillingAddressStr() {
      const address = this.getBillingAddress;
      let addressStr = address.street;
      addressStr += address.street2 ? `, ${address.street2}` : '';
      addressStr += `, ${address.zip} ${address.city}, ${address.state}, ${address.country}`;
      return addressStr;
    },
    getShippingCountry() {
      if (this.billingAddrIsShippingAddr && this.getBillingAddress && this.getBillingAddress.country) return this.getBillingAddress.country;
      else if (this.order.shippingAddress && this.order.shippingAddress.country) return this.order.shippingAddress.country;
      else return "";
    },
    getTotalPrice() {
      let totalPrice = 0;
      this.data.order.forEach((item) => {
        totalPrice += item.qty * item.product.price;
      });
      return totalPrice;
    },
    getVatPercentage() {
      if (this.getShippingCountry.toLowerCase() == "france") return 20;
      else return 0;
    },
    getTotalVat() {
      return this.getTotalPrice * (this.getVatPercentage / 100);
    },
    getTotalPriceWithVat() {
      return this.getTotalPrice + this.getTotalVat;
    },
    isTotalAmountOverLimit() {
      if (!this.data.order) return false;
      if (this.order.paymentMethod == "BANK_TRANSFER") return false;

      let totalPrice = 0;
      this.data.order.forEach((item) => {
        totalPrice += item.product.price * item.qty * 1.2; // Total price with VAT
      });

      return totalPrice > this.maxPaymentAmount;
    },
  },
  mounted() {
    this.fetchClinicPaymentMethods();
  },
  methods: {
    fetchClinicPaymentMethods() {
			ApiService.query(`clinics/${clinicId}/payment-methods`).then(
				({ data }) => {
					this.paymentMethods = data.filter(({ status }) => status == "valid");
          
          // Set default payment method
          if (this.paymentMethods.length) {
            const sepaDebit = this.paymentMethods.find(e => e.method == "directdebit");
            this.order.paymentMethod = sepaDebit ? sepaDebit.id : this.paymentMethods[0].id;
          } else {
            this.order.paymentMethod = "BANK_TRANSFER";
            return;
          }
				}
			);
    },
    paymentMethodToStr(paymentMethod) {
      switch (paymentMethod.method) {
        case "directdebit":
          return `SEPA Direct Debit (${paymentMethod.details.consumerAccount.match(/.{1,4}/g).join(' ')})`;
        case "creditcard":
          return `Credit Card (**** **** **** ${paymentMethod.details.cardNumber})`;
        default:
          return paymentMethod.method;
      }
    },
    onClickConfirm() {
      if (this.$refs.form) {
        this.$refs.form.requestSubmit();
      } else {
        this.createOrder();
      }
    },
    redirectToOrders() {
      this.$router.push('/orders');
    },
    //Calls api and creates order
    createOrder() {
      if (!this.termsAndConditionsAccepted) {
        this.toast.error('Please accept terms and conditions');
        return;
      }

      this.orderLoading = true;

      this.order.billingAddress = this.getBillingAddress;
      if (this.billingAddrIsShippingAddr) {
        this.order.shippingAddress = _.cloneDeep(this.order.billingAddress);
      }

      this.order.products = this.data.order.map(item => ({
        product: item.product.id,
        quantity: item.qty,
      }));

      const config = {
        responseToast: {
          text: "Your order has been placed!"
        }
      }
      
      ApiService
        .post('orders', this.order, config)
        .then(({ data }) => {
          console.log(data);
          if (data.paymentMethod == "BANK_TRANSFER") {
            // Display bank transfer details (with custom reference to insert)
            this.bankDetailsReference = `Neoris Order ${data.number}`;
          } else {
            this.redirectToOrders();
          }
        })
        .catch(e => {
          console.error(e);
          this.orderLoading = false;
        });
    },
    processParams(data) {
      this.data = _.cloneDeep(data);
    },
  },
};
</script>
